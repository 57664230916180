@media only screen and (max-width: 1000px) {

  html {
    /*box-sizing: border-box;*/
  }
  
  *, *::before, *::after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
  }
  
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(113, 207, 219, 0.5);
    box-shadow: 0 0 1px rgba(214, 35, 35, 0.911);
  }
  

  .app {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
  }
  .dashContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    flex-direction: column;
  }

  .polyAccountGraph {
    width: 100%;
  }

  .polyAccountGraphLeft {
    width: 100%;
  }
  
  nav ul {
    list-style: none;
    display: flex;
    margin-bottom: 20px;
    float: right;
  }


nav ul li {
  font-family: 'Lato', 'Avenir', 'sans-serif';
  padding: 8px;
  font-size: 12px;
}

nav ul li a {
  text-decoration: none;
  color: black;
}

nav {
  width:100%;
  height:6vw;
  display: flex;
  justify-content: center;
  padding: 10px;
  z-index: 10;
}
.welcomePrompt {
  position: relative; /* or absolute */
  top: 40%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 2vh;
}

.personal{
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  width: 100%;
  height: 500px;
  float:left;
}
.drawing{
  display:flex;
  flex-direction: column;
  align-items: center;
  
  width:0%;
  height:100%;
  justify-content: center;
  float:right;
}

.current{
  text-decoration: underline;
}

img{
  padding: 20px;
  width: 100%;
  display: block;
}

.current {
  border-bottom: 4px; 
}

.home{
  display: inline;
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
}
h1 {
  margin-bottom: 20px;
  font-family: 'Lato', 'Avenir', 'sans-serif';
  font-size: 25px;
  font-weight: 800;
  margin-top: 10px;
  margin-left: 50%;
}

h2 {
  margin-left: 10%;
  font-size: 20px;
  font-family: 'Lato', 'Avenir', 'sans-serif';
}

p {
  display: block;
  margin-bottom: 15px;
  font-family: 'Lato', 'Avenir', 'sans-serif';
}

#message{
  height: 300px;
  width: 100%;
  padding: 10px;
  font-family: 'Lato', 'Avenir', 'sans-serif';
}

#butt{
  padding: 10px 20px;
  border-radius: 5px;
  background-color: rgba(225,225,225,.7);
  font-family: 'Lato', 'Avenir', 'sans-serif';
}

#butt:hover{
  background-color:rgb(61, 135, 204);
}

#bounding{
  display: none;
}

input{
  display: block;
}

label{
  display: none;
}

}

@media only screen and (min-width: 1000px) and (max-width: 1800px) {

  html {
    box-sizing: border-box;
  }

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(113, 207, 219, 0.5);
  box-shadow: 0 0 1px rgba(214, 35, 35, 0.911);
}

.app {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
}

.dashContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: min-content;
  flex-direction: column;
  overflowY: hidden;
}

.polyAccountGraph {
  width: 50%;
  position: absolute;
  right: 0;
}

.polyAccountGraphLeft {
  width: 48%;
  position: absolute;
  left: 0;
}

.slider li {
  position: unset !important;
  height: unset !important;
  padding-bottom: unset !important;
}
.slider li .carousel__inner-slide {
  position: unset;
}

.slider-container {
  width: 100%;
  height: 100%;
  position:fixed; /* add this code for position fixed */
  top:0px; /* set top and left 0 */
  left:0px;
}

nav ul {
  list-style: none;
  display: flex;
  margin-bottom: 20px;
  float: right;
}


nav ul li {
font-family: 'Lato', 'Avenir', 'sans-serif';
padding: 40px;
font-size: 20px;
}

nav ul li a {
text-decoration: none;
color: black;
}

nav {
  width:100%;
  height:100px !important;
  height: 80px;
  display: flex;
  justify-content: center;
  padding: 10px;
  z-index: 10;
}

.welcomePrompt {
  position: relative; /* or absolute */
  top: 40%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 3em;
}
.welcomeSubtitle {
  top: 40%;
  transition-timing-function: ease-in-out;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
.personal{
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  float: left;
  width:50%;
  height: 60vh;
  overflow-y:scroll;
  /* width: 65%; */
}
.slider-container {
  width: 100%;
  height: 100%;
  
  position:fixed;
  top:0;
  left:0;
  
  }
.drawing{
  width:49%;
  float:right;
}
.current{
  text-decoration: underline;
  border-bottom: 4px;
}

img{
  padding: 20px;
  width: 95%;
  display: block;
}

h1 {
  margin-top: 20px;
  margin-left: 50%;
  margin-bottom: 20px;
  font-family: 'Lato', 'Avenir', 'sans-serif';
  font-size: 55px;
  font-weight: 1600;
}

h2 {
  margin-left: 10%;
  font-size: 20px;
  font-family: 'Lato', 'Avenir', 'sans-serif';
}

p {
  display: block;
  margin-bottom: 15px;
  font-family: 'Lato', 'Avenir', 'sans-serif';
}


#message{
  height: 200px;
  width: 600px;
  padding: 10px;
  font-family: 'Lato', 'Avenir', 'sans-serif';
}


#bounding{
  height: 500px;
  width: 1000px;
}

label{
  display: none;
}

input{
  padding: 10px;
  margin: 1%;
  display: block;
}
/*
svgLines{
  display: inline;
  height: 1300px;
  top:0;
  bottom: 0;
}
*/
.home{
  display: inline;
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  align-content:center;
  justify-content: center;
  margin: 0 auto;
}
 
#butt{
  padding: 10px 20px;
  border-radius: 5px;
  background-color: rgba(225,225,225,.7);
  font-family: 'Lato', 'Avenir', 'sans-serif';
}

#butt:hover{
  background-color:  #1a73ab;
}

}
button{
  background:#1a73ab;
  color:#fff;
  border:none;
  position:relative;
  height:60px;
  padding:0 1.8em;
  cursor:pointer;
  transition:800ms ease all;
  outline:none;
}
button:hover{
  background:#fff;
  color:#1a73ab;
}
button:before,button:after{
  content:'';
  position:absolute;
  top:0;
  right:0;
  height:2px;
  width:0;
  background: #1a73ab;
  transition:400ms ease all;
}
button:after{
  right:inherit;
  top:inherit;
  left:0;
  bottom:0;
}
button:hover:before,button:hover:after{
  width:100%;
  transition:800ms ease all;
}

@media only screen and (min-width: 1800px) {

  html {
    box-sizing: border-box;
  }
  
  *, *::before, *::after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(113, 207, 219, 0.5);
    box-shadow: 0 0 1px rgba(214, 35, 35, 0.911);
  }
  
  
  button{
    background:#1a73ab;
    color:#fff;
    border:none;
    position:relative;
    height:60px;
    padding:0 2em;
    cursor:pointer;
    transition:800ms ease all;
    outline:none;
  }
  button:hover{
    background:#fff;
    color:#1a73ab;
  }
  button:before,button:after{
    content:'';
    position:absolute;
    top:0;
    right:0;
    height:2px;
    width:0;
    background: #1a73ab;
    transition:400ms ease all;
  }
  button:after{
    right:inherit;
    top:inherit;
    left:0;
    bottom:0;
  }
  button:hover:before,button:hover:after{
    width:100%;
    transition:800ms ease all;
  }
  .app {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
  }

  .dashContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: min-content;
    flex-direction: column;
    overflowY: hidden;
  }
  
  .polyAccountGraph {
    width: 50%;
    position: absolute;
    right: 0;
  }
  
  .polyAccountGraphLeft {
    width: 48%;
    position: absolute;
    left: 0;
  }
  
  
  nav ul {
    position: right;
    list-style: none;
    display: flex;
  }
  
  nav ul li {
    font-family: 'Lato', 'Avenir', 'sans-serif';
    padding: 40px;
    font-size: 20px;
  }
  
  nav ul li a {
    text-decoration: none;
    color: black;
  }

  nav {
    width:100%;
    height:100px !important;
    height: 80px;
    display: flex;
    justify-content: center;
    padding: 10px;
    z-index: 10;
  }
  
  .welcomePrompt {
    position: relative; /* or absolute */
    top: 40%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 5em;
  }

  .personal{
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    float: left;
    width:50%;
    height: 75vh;
    overflow-y:scroll;
    /* width: 65%; */
  }
  .slider-container {
    width: 100%;
    height: 100%;
    
    position:fixed;
    top:0;
    left:0;
    
    }
  
  .current{
    text-decoration: underline;
  }

  .drawing{
    width:49%;
    height:100%;
    float:right;
  }
  
  img{
    padding: 20px;
    width: 40%;
    display: block;
  }
  
  .current {
    border-bottom: 4px; 
  }
  
  h1 {
    margin-bottom: 20px;
    font-family: 'Lato', 'Avenir', 'sans-serif';
    font-size: 55px;
    font-weight: 1600;
    margin-left: 50%;
    margin-top: 20px;
  }
  
  h2 {
    margin-left: 10%;
    font-size: 20px;
    font-family: 'Lato', 'Avenir', 'sans-serif';
  }
  
  p {
    display: block;
    margin-bottom: 15px;
    font-family: 'Lato', 'Avenir', 'sans-serif';
  }
  
  #message{
    height: 200px;
    width: 600px;
    padding: 10px;
    font-family: 'Lato', 'Avenir', 'sans-serif';
  }
  
  
  #bounding{
    height: 500px;
    width: 1000px;
  }
  
  label{
    display: none;
  }
  
  input{
    padding: 10px;
    margin: 1%;
    display: block;
  }

  .home{
    display: inline;
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    align-content:center;
    justify-content: center;
  }
  
  
  
  #butt{
    padding: 10px 20px;
    border-radius: 5px;
    background-color: rgba(225,225,225,.7);
    font-family: 'Lato', 'Avenir', 'sans-serif';
  }
  
  #butt:hover{
    background-color: rgb(61, 135, 204);
  }
}